import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "../styles/home.module.scss";
import postStyles from "../styles/post.module.scss";

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout hideHeader={false}>
      <SEO linkImage="cover-home.jpg" />
      <div className={styles.indexImageWrap}>
        <div className={styles.indexImageOverlay}>
          <div className={styles.indexImage} />
          <div className={styles.indexContent}>
            <h1>law.photography</h1>
            <h2>A collection of Photography Laws around the world</h2>
          </div>
        </div>
      </div>
      <div className={postStyles.postContainer}>
        <div className={postStyles.post}>
          <h2>Featured Pages:</h2>
          <div className={styles.featuredContainer}>
            <div className={styles.featuredItem}>
              <Link to="/law/street-photography-laws-in-germany">
                <img
                  className={styles.featuredImage}
                  src="/images/cover-laws.jpg"
                  alt="law books"
                />
              </Link>
              <p>
                <Link to="/law/street-photography-laws-in-germany">
                  Street Photography in Germany
                </Link>
              </p>
            </div>
            <div className={styles.featuredItem}>
              <Link to="/law/drone-photography-laws-in-denmark">
                <img
                  className={styles.featuredImage}
                  src="/images/cover-drone.jpg"
                  alt="camera drone"
                />
              </Link>
              <p>
                <Link to="/law/drone-photography-laws-in-denmark">
                  Drone Photography in Denmark
                </Link>
              </p>
            </div>
            <div className={styles.featuredItem}>
              <Link to="/law/filming-permits-in-germany">
                <img
                  className={styles.featuredImage}
                  src="/images/cover-contract.jpg"
                  alt="photography contract"
                />
              </Link>
              <p>
                <Link to="/law/filming-permits-in-germany">
                  Getting Photography Permits in Germany
                </Link>
              </p>
              <p />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
          fields {
            path
          }
        }
      }
    }
  }
`;
